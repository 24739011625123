import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormattedMessage, { formatMessage } from '../../src/lib/i18n';

import styles from './fullscreen-button.scss';

class FullscreenButton extends PureComponent {
  render() {
    const {
      isPointerActive,
      isFullscreen,
      enableClickAnywhere,
      onEnterFullscreen,
      onExitFullscreen,
    } = this.props;

    let message;

    if (enableClickAnywhere) {
      message = isFullscreen
        ? <FormattedMessage id="button.fullscreen.exit" />
        : <FormattedMessage id="button.fullscreen.enter" />;
    }

    const icon = isFullscreen
      ? <i className={'fa fa-compress'} />
      : <i className={'fa fa-expand'} />;

    return (
      <button
        title={formatMessage('dashboard.enterFullscreen')}
        className={classNames(styles.button, {
          [styles.idle]: !isPointerActive,
        })}
        onClick={isFullscreen ? onExitFullscreen : onEnterFullscreen}
      >
        <span>
          {icon}
          {message &&
            <span className={styles.message}>
              {message}
            </span>}
        </span>
      </button>
    );
  }
}

FullscreenButton.propTypes = {
  isFullscreen: PropTypes.bool,
  isPointerActive: PropTypes.bool,
  enableClickAnywhere: PropTypes.bool,
  onEnterFullscreen: PropTypes.func,
  onExitFullscreen: PropTypes.func,
};

export default FullscreenButton;
