import { FormattedMessage as IntlFormattedMessage } from 'react-intl';

import ConfiguredI18NProvider from './configured-i18n-provider-component';
import { formatMessage } from './format-message';
import translationKeyWrapper, {
  translationKeyWrapperHTML,
} from './translation-key-wrapper';

const FormattedMessage = translationKeyWrapper(IntlFormattedMessage);
const FormattedHTMLMessage = translationKeyWrapperHTML(IntlFormattedMessage);

FormattedMessage.displayName = 'FormattedMessage';
FormattedHTMLMessage.displayName = 'FormattedHTMLMessage';

// formatMessage is exposed to gecko-js
export {
  ConfiguredI18NProvider,
  formatMessage,
  FormattedHTMLMessage,
  FormattedMessage as default,
};
