import MessageFormat from 'intl-messageformat';
import { isUndefined } from 'lodash';

import Content from '../../../content/default.json';

const formatMessage = (key, values, content = Content) => {
  const item = content[key];
  if (isUndefined(item)) {
    return key;
  }

  const message = new MessageFormat(item, 'en');
  return message.format(values);
};

export { formatMessage };
