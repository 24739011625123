import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullscreenButtonComponent from './fullscreen-button';
import fscreen from 'fscreen';
import { bindAll } from 'lodash';

import style from './outer-frame-control-style.scss';

class OuterFrameControlComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isFullscreen: this.isFullscreen(), isPointerActive: false };
    this.idleTimer = null;

    bindAll(this, [
      'handleClickAnywhere',
      'handleFullscreenChange',
      'handleEnterFullscreen',
      'handleExitFullscreen',
      'handleMouseMove',
      'onMessageReceived',
    ]);
  }

  componentDidMount() {
    const iframe = this.getIframe();

    fscreen.addEventListener('fullscreenchange', this.handleFullscreenChange);

    if (this.props.enableClickAnywhere) {
      // If enableClickAnywhere is enabled, we're disabling all
      // pointer events on the iframe, so that we can listen to mousemove
      // and click events of the parent frame
      iframe.style.pointerEvents = 'none';
      document.addEventListener('click', this.handleClickAnywhere, true);
      document.addEventListener('mousemove', this.handleMouseMove, true);
    }

    window.addEventListener('message', this.onMessageReceived, false);

    if (!this.state.isFullscreen) {
      this.idleTimer = setTimeout(
        () => this.setState({ isPointerActive: false }),
        2000,
      );
    }
  }

  componentWillUnmount() {
    const iframe = this.getIframe();

    fscreen.removeEventListener(
      'fullscreenchange',
      this.handleFullscreenChange,
    );

    if (this.props.enableClickAnywhere) {
      iframe.style.pointerEvents = 'auto';
      document.removeEventListener('click', this.handleClickAnywhere, true);
      document.removeEventListener('mousemove', this.handleMouseMove, true);
    }

    window.removeEventListener('message', this.onMessageReceived, false);

    clearTimeout(this.idleTimer);
  }

  handleClickAnywhere(e) {
    // Capture event at the top level (document) and stop propagation
    // to the button element
    e.stopPropagation();
    if (this.isFullscreen()) {
      fscreen.exitFullscreen();
    } else {
      fscreen.requestFullscreen(document.body);
    }
  }

  handleEnterFullscreen() {
    fscreen.requestFullscreen(document.body);
  }

  handleExitFullscreen() {
    fscreen.exitFullscreen();
  }

  handleFullscreenChange() {
    const isFullscreen = this.isFullscreen();
    const iframeWindow = this.getIframe().contentWindow;

    this.setState({ isFullscreen });
    iframeWindow.postMessage(
      { type: 'handleFullscreenChange', isFullscreen },
      '*',
    );
  }

  handleMouseMove() {
    clearTimeout(this.idleTimer);
    this.setState({ isPointerActive: true });

    this.idleTimer = setTimeout(
      () => this.setState({ isPointerActive: false }),
      2000,
    );
  }

  onMessageReceived(message) {
    switch (message.data.type) {
      case 'handleMousemove':
        this.handleMouseMove();
        break;
      case 'reloadPage':
        window.location.reload();
        break;
      default:
        break;
    }
  }

  getIframe() {
    return document.getElementById('gb-dashboard-iframe');
  }

  render() {
    return (
      <div className={style.fullscreenButton}>
        <FullscreenButtonComponent
          {...this.props}
          isPointerActive={this.state.isPointerActive}
          isFullscreen={this.state.isFullscreen}
          onEnterFullscreen={this.handleEnterFullscreen}
          onExitFullscreen={this.handleExitFullscreen}
        />
      </div>
    );
  }

  isFullscreen() {
    if (fscreen.fullscreenElement !== null) {
      return true;
    }

    return false;
  }
}

OuterFrameControlComponent.propTypes = {
  enableClickAnywhere: PropTypes.bool,
};

export default OuterFrameControlComponent;
