import 'core-js/stable';
import 'regenerator-runtime/runtime';
/*
 * Add Intl support for old browsers. 'intl' handles all the Polyfill
 * logic internally so we just need to import it along with the data
 * for locales we want to support. When all browsers that we want
 * to support have native Intl support, we can delete this.
 */
import 'intl';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfiguredI18NProvider } from '../src/lib/i18n';
import HistoryProvider from '../src/lib/history-provider';
import OuterFrameControlComponent from './outer-frame-control-component';

class OuterFrameControl {
  constructor(domHost) {
    this.domHost = domHost;
  }

  render(props) {
    window.__REACT_VERSION__ = React.version;

    ReactDOM.render(
      <ConfiguredI18NProvider>
        <HistoryProvider>
          <OuterFrameControlComponent {...props} />
        </HistoryProvider>
      </ConfiguredI18NProvider>,
      this.domHost,
    );
  }

  remove() {
    ReactDOM.unmountComponentAtNode(this.domHost);
  }
}

export { OuterFrameControl };
