import { isUndefined } from 'lodash';

export const splitQueryString = (query = '') => {
  if (query.length === 0) {
    return {};
  }

  const list = query.replace(/^\?/, '').split('&');

  return list.reduce((acc, item) => {
    const [key, value] = item.split('=');

    return { ...acc, [key]: value };
  }, {});
};

export const joinQueryParams = (params = {}) => {
  return Object.keys(params).reduce((acc, key, index) => {
    return index === 0
      ? `?${key}=${params[key]}`
      : `${acc}&${key}=${params[key]}`;
  }, '');
};

export const buildUrlWithParams = (url, params = {}) => {
  const entries = Object.entries(params).filter(
    ([, value]) => !isUndefined(value),
  );

  if (!entries.length) {
    return url;
  }

  const queryString = entries
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${url}?${queryString}`;
};
