import PropTypes from 'prop-types';
import React from 'react';

/*
 * Components can be wrapped with a translationKeyWrapper which
 * adds a span that shows the translation id in a data-t attribute.
 * That way people can easily look up translations in the content files.
 */
function translationKeyWrapper(DecoratedComponent) {
  const WrappedComponent = (props) => (
    <span data-t={props.id}>
      <span>
        <DecoratedComponent {...props} />
      </span>
    </span>
  );

  WrappedComponent.propTypes = {
    ...DecoratedComponent.propTypes,
    id: PropTypes.string.isRequired,
  };

  return WrappedComponent;
}

function translationKeyWrapperHTML(DecoratedComponent) {
  const WrappedComponent = (props) => (
    <span data-t={props.id}>
      <span>
        <DecoratedComponent
          {...props}
          values={{
            linebreak: <br />,
            a: (chunks) => <a>{chunks}</a>,
            b: (chunks) => <b>{chunks}</b>,
            ul: (chunks) => <ul>{chunks}</ul>,
            li: (chunks) => <li>{chunks}</li>,
            strong: (chunks) => <strong>{chunks}</strong>,
            em: (chunks) => <em>{chunks}</em>,
            span: (chunks) => <span>{chunks}</span>,
            i: (chunks) => <i>{chunks}</i>,
            h1: (chunks) => <h1>{chunks}</h1>,
            h2: (chunks) => <h2>{chunks}</h2>,
            h3: (chunks) => <h3>{chunks}</h3>,
            p: (chunks) => <p>{chunks}</p>,
            div: (chunks) => <div>{chunks}</div>,
            ...props.values,
          }}
        />
      </span>
    </span>
  );

  WrappedComponent.propTypes = {
    ...DecoratedComponent.propTypes,
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
  };

  return WrappedComponent;
}

export default translationKeyWrapper;
export { translationKeyWrapperHTML };
